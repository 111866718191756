<!-- 
    页面 系统配置
-->
<template>
  <div id="customize-page">
    <div class="customize-header">
      <h1 class="text-center">系统配置页面</h1>
      <div class="px-6">
        <!-- 整体风格 -->
        <div class="mt-4">
          <h4 class="mb-5">整体风格</h4>
          <div>
            <vs-radio class="mr-4" v-model="theme" vs-value="light"
              >明亮模式</vs-radio
            >
            <vs-radio class="mr-4" v-model="theme" vs-value="dark"
              >暗夜模式</vs-radio
            >
            <vs-radio v-model="theme" vs-value="semi-dark">半夜模式</vs-radio>
          </div>
        </div>
        <vs-divider></vs-divider>

        <!-- 整体色彩 -->
        <div>
          <h4 class="mb-5">整体色彩</h4>
          <ul class="clearfix">
            <li
              @click="updatePrimaryColor(color)"
              v-for="color in themeColors"
              class="w-10 cursor-pointer h-10 rounded-lg m-2 float-left"
              :style="{ backgroundColor: color }"
              :class="{ 'shadow-outline': color == primaryColor }"
              :key="color"
            ></li>
            <li
              @click="updatePrimaryColor(customPrimaryColor)"
              class="w-10 cursor-pointer h-10 rounded-lg m-2 float-left"
              :style="{ backgroundColor: customPrimaryColor }"
              :class="{ 'shadow-outline': customPrimaryColor == primaryColor }"
            ></li>
            <li class="float-left">
              <input
                class="w-10 cursor-pointer h-10 rounded-lg m-2"
                v-model="customPrimaryColor"
                type="color"
              />
            </li>
          </ul>
        </div>
        <vs-divider></vs-divider>

        <!-- 过长动画 -->
        <div class="mt-4">
          <h4 class="mb-5">过长动画</h4>
          <div>
            <vs-radio
              class="mr-4"
              v-model="routerTransition"
              v-for="(item, index) in routerTransitionsList"
              :key="index"
              :vs-value="item.value"
            >
              {{ item.text }}
            </vs-radio>
          </div>
        </div>
        <vs-divider></vs-divider>

        <!-- 导航色彩 -->
        <div class="mt-4">
          <h4 class="mb-5">导航色彩</h4>
          <ul class="clearfix">
            <!-- 默认白色 -->
            <li
              class="w-10 m-2 h-10 rounded-lg bg-white float-left cursor-pointer border border-solid border-grey-light"
              :class="navbarColorOptionClasses('#fff')"
              @click="navbarColorLocal = '#fff'"
            />

            <!-- 颜色条 -->
            <li
              class="w-10 cursor-pointer h-10 rounded-lg m-2 float-left"
              :class="navbarColorOptionClasses(color)"
              :style="{ backgroundColor: color }"
              v-for="color in themeColors"
              :key="color"
              @click="navbarColorLocal = color"
            />

            <!-- 选色盘 -->
            <li
              class="w-10 cursor-pointer h-10 rounded-lg m-2 float-left"
              :class="navbarColorOptionClasses(customNavbarColor)"
              :style="{ backgroundColor: customNavbarColor }"
              @click="navbarColorLocal = customNavbarColor"
            />

            <li class="float-left">
              <input
                class="w-10 cursor-pointer h-10 rounded-lg m-2"
                v-model="customNavbarColor"
                type="color"
              />
            </li>
          </ul>
        </div>
        <vs-divider></vs-divider>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      themeColors: [
        "#787878",
        "#7367F0",
        "#3176F5",
        "#9B24EB",
        "#D81CD8",
        "#FD12CA",
        "#EA5455",
        "#E5FF0E",
        "#FF9F43",
        "#28C76F",
        "#00EBDB"
      ],
      customPrimaryColor: "#3DC9B3",
      customNavbarColor: "#3DC9B3",
      navbarType: "static",
      routerTransitionsList: [
        { text: "无需特效", value: "none" },
        { text: "快速淡出", value: "zoom-fade" },
        { text: "侧边淡出", value: "slide-fade" },
        { text: "底部淡出", value: "fade-bottom" },
        { text: "淡入淡出", value: "fade" },
        { text: "快速离开", value: "zoom-out" }
      ]
    };
  },
  computed: {
    theme: {
      get() {
        return this.$store.state.theme;
      },
      set(val) {
        console.log("theme", val);
        this.$store.dispatch("updateTheme", val);
      }
    },
    primaryColor: {
      get() {
        return this.$store.state.themePrimaryColor;
      },
      set(val) {
        this.$store.commit("UPDATE_PRIMARY_COLOR", val);
      }
    },
    routerTransition: {
      get() {
        return this.$store.state.routerTransition || "none";
      },
      set(val) {
        this.$store.dispatch("updateRouterTransition", val);
      }
    },
    navbarColorLocal: {
      get() {
        return this.$store.state.navbarColor;
      },
      set(val) {
        this.$store.dispatch("updateNavbarColor", val);
        this.$emit("updateNavbarColor", val);
      }
    },
    navbarColorOptionClasses() {
      return color => {
        let classes = {};
        if (color == this.navbarColorLocal) classes["shadow-outline"] = true;
        return classes;
      };
    }
  },
  methods: {
    updatePrimaryColor(color) {
      this.primaryColor = color;
      this.$vs.theme({ primary: color });
    }
  }
};
</script>
